<h3>My Publications </h3>




<div style="padding:1rem;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: center;align-items: stretch; ">


    <div *ngFor=" let publication of publications " style="width:420px;margin: 1em; ">

        <app-publication-card [publication]=publication></app-publication-card>
    </div>
</div>


<!-- <app-authors-universe></app-authors-universe> -->
